import React, { FunctionComponent } from "react";
import "./styles.scss";
import Section from "../../Section";
import RichText from "../../RichText";

interface BluePrintJoinUsInterface {
  palleteColorList?: {
    bgColor: {
      value: string;
      title: string;
    };
  };
  _rawDescription: any;
  title: string;
}

const BluePrintJoinUs: FunctionComponent<BluePrintJoinUsInterface> = props => {
  const { palleteColorList, _rawDescription, title } = props;
  return (
    <Section sectionClassName="c-bluePrint-joinus" theme={palleteColorList?.bgColor.title}>
      <div className="c-bluePrint-joinus__content">
        <h2 className="c-bluePrint-joinus__title">{title}</h2>
        <RichText data={_rawDescription} />
      </div>
    </Section>
  );
};

export default BluePrintJoinUs;
